import { Injectable } from '@angular/core';
import { LoginObject } from '../../assets/data/interfaces/user.interface'
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LoginObjectService {

  loginObject: LoginObject = {
    username: null,
    password: null,
    email: null,
    roles: null,
    userId: null,
    captchaToken: null,
    clientes: null,
    bloqueado: [],
    mostrarOfertas: false,
    sessionTime: null,
    version:null
  };

  constructor() { }

  setUsername(username) {
    this.loginObject.username = username;
  }

  getUsername() {
    return this.loginObject.username;
  }

  setPassword(password) {
    this.loginObject.password = password;
  }

  getPassword() {
    return this.loginObject.password;
  }

  setEmail(email) {
    this.loginObject.email = email;
  }

  getEmail() {
    return this.loginObject.email;
  }

  setRoles(roles) {
    this.loginObject.roles = roles;
  }

  getRoles() {
    return this.loginObject.roles;
  }
  setVersion(version) {
    this.loginObject.version = version;
  }

  getVersion() {
    return this.loginObject.version;
  }
  setCaptchaToken(captchaToken) {
    this.loginObject.captchaToken = captchaToken;
  }

  getCaptchaToken() {
    return this.loginObject.captchaToken;
  }

  setUserId(userId) {
    this.loginObject.userId = userId;
  }

  getUserId() {
    return this.loginObject.userId;
  }

  setClientes(clientes) {
    this.loginObject.clientes = clientes;
  }

  getclientes() {
    return this.loginObject.clientes;
  }

  setBloqueado(bloqueado) {
    this.loginObject.bloqueado = bloqueado;
  }

  getBloqueado() {
    return this.loginObject.bloqueado;
  }

  setMostrarOFertas(mostrar) {
    this.loginObject.mostrarOfertas = mostrar;
  }

  getMostrarOFertas() {
    return this.loginObject.mostrarOfertas;
  }

  setSessionTime(time) {
    this.loginObject.sessionTime = time;
  }

  getSessionTime() {
    return this.loginObject.sessionTime;
  }

  encrypt(password) {
    return CryptoJS.AES.encrypt(JSON.stringify(password), 'patata').toString();
  }

  decrypt(password) {
    return JSON.parse(CryptoJS.AES.decrypt(password, 'patata').toString(CryptoJS.enc.Utf8));
  }

}
