<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header translucent="true">
        <ion-toolbar class="toolbar-bg">
          <div class="vertical">
            <ion-img class="menuImg" src="assets/logos/logo_2.png"></ion-img>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>

        <ion-list lines="none">
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false"
              [ngClass]="(p.title == 'Ofertas' && showOfertasMenu == false) || (p.title != 'Compras' && menuLimit == true) ? 'no-display' : '' "
              class="bottom-line">
              <fa-icon slot="start" [icon]="p.icon"></fa-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

      </ion-content>
      <ion-footer>
        <ion-row class="row-version">
          <ion-col size="6"class="ion-no-padding version-text verticalLeft" *ngIf="politica">
            <a (click)="openPdf()" >Política de privacidad</a>
          </ion-col>
          <ion-col size="" class="ion-no-padding version-text">
            <ion-label>v. {{version}}</ion-label>
          </ion-col>
        </ion-row>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>