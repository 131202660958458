import { Injectable } from '@angular/core';
import * as fas from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class FontAwesomeService {
  public faClock = fas.faClock;
  public faFish = fas.faFish;
  public faShip = fas.faShip;
  public faWeightHanging = fas.faWeightHanging;
  public faCalendarDay = fas.faCalendarDay;
  public faAnchor = fas.faAnchor;
  public faCoins = fas.faCoins;
  public faUser = fas.faUser;
  public faBookmark = fas.faBookmark;
  public faTag = fas.faTag;
  public faStore = fas.faStore;
  public faUsers = fas.faUsers;
  public faCamera = fas.faCamera;
  public faExclamation = fas.faExclamation;
  public faPlus = fas.faPlus;
  public fasCoins = fas.faCoins;
  public faTrash = fas.faTrash;
  public faMinus = fas.faMinus;
  public faCaretDown = fas.faCaretDown;
  public faCaretUp = fas.faCaretUp;
  public faWifi = fas.faWifi;
  public faPowerOff = fas.faPowerOff;
  public faLock = fas.faLock;
  public faBell = fas.faBell;
  public faBasket = fas.faBasketShopping;
  public faTrendUp = fas.faArrowTrendUp;
  public faPlay = fas.faPlay;
  public faStop = fas.faStop;
  public faPaperPlane = fas.faPaperPlane;
  public faEllipsisVertical = fas.faEllipsisVertical;
  public faCheck = fas.faCheck;
  public faHandPointUp = fas.faHandPointUp;
  public faCartShopping = fas.faCartShopping;
  public faArrowUp = fas.faArrowUp;
  public faArrowDown = fas.faArrowDown;
  public faRotateRight = fas.faRotateRight;
  public faTruckRampBox = fas.faTruckRampBox;
  public faPenToSquare = fas.faPenToSquare;
  public faIndustry = fas.faIndustry;
  public faList = fas.faList;
  public faComment = fas.faComment;
  public faUserGroup = fas.faUserGroup;
  public faGear = fas.faGear;
  public faCaretLeft = fas.faCaretLeft
  public faCaretRight = fas.faCaretRight;
  public faKey = fas.faKey;
  public faX = fas.faX;
  public faDownload = fas.faDownload;
  public faEye = fas.faEye;
  public faEyeSlash = fas.faEyeSlash;
  public faCircleInfo = fas.faCircleInfo;
  public faArrowRight = fas.faArrowRight;
  public faArrowLeft = fas.faArrowLeft;

  constructor() { }

}
