<ion-content>

  <ion-grid class="ion-no-padding" style="display:flex;flex-direction: column;height: 100%;background-color: #7194a5">
    <ion-row class="labelData" style="padding: 5px;">
      <ion-col style="padding: 5px;font-size: 22px;"> <b>Cesión</b></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <mat-tab-group #tab style="overflow:hidden;" color="primary" (selectedTabChange)="tabChange($event)"
          dynamicHeight class="tabs">
          <mat-tab label="Crear Cesión" style="overflow: hidden;" dynamicHeight>
            <ion-row class="titulo">
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Proveedor" matTooltipClass="tooltipClass"
                [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon" [icon]="iconsService.faShip"></fa-icon>
                <ion-label>
                  {{datos.proveedor}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Especie" matTooltipClass="tooltipClass"
                [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon"  [icon]="iconsService.faFish"></fa-icon>
                <ion-label>
                  {{datos.especie}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Cantidad Retirada"
                matTooltipClass="tooltipClass" [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon"  [icon]="iconsService.faWeightHanging"></fa-icon>
                <ion-label>
                  {{datos.cantidad | number:'1.2-2':'es'}} {{datos.unidad}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Cantidad Restante"
                matTooltipClass="tooltipClass" [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon"  [icon]="iconsService.faTruckRampBox"></fa-icon>
                <ion-label>
                  {{datos.cantidad - totalCedido| number:'1.2-2':'es'}} {{datos.unidad}}
                </ion-label>
              </ion-col>

              <!--<ion-col class="labelData vertical">
              <ion-label>
                <b>€/KG</b> {{datos.precio | number:'1.2-2':'es'}} €
              </ion-label>
            </ion-col>-->
            </ion-row>
            <form [formGroup]="form" class="form-container">
              <ion-row style="background-color: #7194a5;">
                <ion-col size="8" style="padding: 1px;">
                  <mat-form-field  appearance="outline" style="width: 100%;">
                    <!--<mat-label
                    style="font-size: 16px;color:black;background-color: white;border:1px solid transparent;border-radius: 10px;padding: 3px;">
                    Vendeduría
                  </mat-label>-->
                    <span matTextPrefix>
                      <fa-icon class="" [icon]="iconsService.faIndustry"></fa-icon>
                    </span>
                    <input type="text" matInput formControlName="vendeduria" placeholder="Vendeduría"
                      [matAutocomplete]="auto" autofocus="false" style="font-size:12px">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" style="border-radius: none;">
                      <mat-option *ngFor="let vendeduria of filterVendedurias | async" [value]="vendeduria"
                        style="font-size:12px">
                        {{ vendeduria.nombre }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </ion-col>
                <ion-col></ion-col>
                <ion-col class="verticalRight" size="4">

                  <mat-form-field appearance="outline" class="custom-color">
                    <span matTextPrefix>
                      <fa-icon class="" [icon]="iconsService.faWeightHanging"></fa-icon>
                    </span>
                    <input matInput formControlName="cantidad" placeholder="Cantidad"
                      style="text-align: right; width: 100%;font-size:12px" (input)="onChange($event)"
                      (blur)="changeValue($event)" (focus)='$event.target.select()'
                      (keydown)="numberOnlyValidation($event)" inputmode="decimal" class="inputUsuario">
                  </mat-form-field>
                </ion-col>
              </ion-row>
              <ion-row style="background-color: #7194a5">
                <ion-col style="padding: 1px;">
                  <mat-form-field appearance="outline" style="text-align: left; width: 100%; resize: none;">
                    <textarea matInput rows="9" placeholder="Observaciones" formControlName="observaciones"
                      spellcheck="true"></textarea>
                  </mat-form-field>
                </ion-col>
              </ion-row>
            </form>
          </mat-tab>
          <mat-tab label="Listado de Cesiones" style="overflow: hidden;" dynamicHeight>
            <ion-row class="titulo">
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Proveedor" matTooltipClass="tooltipClass"
                [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon" [icon]="iconsService.faShip"></fa-icon>
                <ion-label>
                  {{datos.proveedor}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Especie" matTooltipClass="tooltipClass"
                [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon" [icon]="iconsService.faFish"></fa-icon>
                <ion-label>
                  {{datos.especie}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Cantidad Retirada"
                matTooltipClass="tooltipClass" [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon" [icon]="iconsService.faWeightHanging"></fa-icon>
                <ion-label>
                  {{datos.cantidad | number:'1.2-2':'es'}} {{datos.unidad}}
                </ion-label>
              </ion-col>
              <ion-col class="labelData verticalLeft" size="6" matTooltip="Cantidad Restante"
                matTooltipClass="tooltipClass" [matTooltipShowDelay]="1000">
                <fa-icon class="headerIcon" [icon]="iconsService.faTruckRampBox"></fa-icon>
                <ion-label>
                  {{datos.cantidad - totalCedido| number:'1.2-2':'es'}} {{datos.unidad}}
                </ion-label>
              </ion-col>
              <!--<ion-col class="labelData vertical">
              <ion-label>
                <b>€/KG</b> {{datos.precio | number:'1.2-2':'es'}} €
              </ion-label>
            </ion-col>-->
            </ion-row>
            <ion-row class="cabecera">
              <ion-col style="padding:10px">
                <ion-label>
                  VENDEDURÍA
                </ion-label>
              </ion-col>
              <ion-col class="verticalRight" size="2" style="padding:10px">
                <ion-label>
                  CANT.
                </ion-label>
              </ion-col>
              <ion-col size="1"></ion-col>
            </ion-row>
            <ion-row *ngFor="let cesion of cesiones" (click)="select(cesion)">
              <ion-col class="ion-no-padding">
                <ion-row style="border-top: 1px solid #e6e9f2;background-color: white;">
                  <ion-col style="padding:10px">
                    <ion-label>
                      {{cesion.vendeduria}}
                    </ion-label>
                  </ion-col>
                  <ion-col class="verticalRight" style="padding:10px" size="2">
                    <ion-label>
                      {{cesion.cantidad | number:'1.2-2':'es'}}
                    </ion-label>
                  </ion-col>
                  <ion-col size="1" style="padding:10px" (click)="deleteCesion(cesion.idCesion)">
                    <fa-icon class="" [icon]="iconsService.faTrash"></fa-icon>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="cesionSelect==cesion && cesion.observaciones">
                  <ion-col style="padding:10px;background-color: #f1f1f1;">
                    <ion-label>
                      {{cesion.observaciones}}
                    </ion-label>
                  </ion-col>
                </ion-row>
              </ion-col>

            </ion-row>


            <ion-row *ngIf=" cesiones.length==0" style="padding: 10px;background-color: white;">
              <ion-col>
                Aun no se ha realizado ninguna cesión.
              </ion-col>

            </ion-row>
            <ion-row class="cabecera">
              <ion-col class=" verticalRight" size="" style="padding:10px">
                Cedida: {{ totalCedido| number:'1.2-2':'es'}} KG
              </ion-col>
              <ion-col size="0.5"></ion-col>
            </ion-row>
          </mat-tab>
        </mat-tab-group>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
<ion-footer style="height:55px">
  <ion-row style="height:100%">
    <ion-col size="" class="ion-no-padding">
      <ion-button class="cerrar" mat-dialog-close (click)="cerrarModal()">Cerrar
      </ion-button>
    </ion-col>
    <ion-col class="ion-no-padding" *ngIf="paginado">
      <ion-button class="save" [disabled]="form.invalid || (datos.cantidad  -  totalCedido) <=0"
        (click)="save()">Guardar
      </ion-button>
    </ion-col>

  </ion-row>
</ion-footer>