import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { LoginObjectService } from './login-object.service';
import { TokenData } from './oauth2-service';

@Injectable({
  providedIn: 'root'
})

/**
 * Control de movimiento en la app mediante ruta. Si hay datos de usuario guardado en el storage se permite, de no ser así se reenvía al usuario al login.
 */
export class AuthguardService implements CanActivate {
  constructor(
    private router: Router,
    public storage: Storage,
    public loginObjectService: LoginObjectService,
    public tokenData: TokenData,
  ) { }
  // async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const currentUser = await this.storage.get("user");
  //   if (currentUser ) {
  //     return true;
  //   }
  //   this.router.navigate(["/login"])
  //   return false;
  // }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Para evitar acceder por ruta a secciones de la app en web.
    if (!this.loginObjectService.loginObject.userId) { this.tokenData.stopTimer(); this.router.navigate(["/login"]); return false; }

    const currentUser = await this.storage.get("user");
    if (currentUser) {

      

      if (currentUser['bloqueado'].includes('NOSUBASTA') && (state.url.includes('/subasta') || state.url.includes('/listado-subastas'))) {
        this.router.navigate(["/compras"]);
        return false;
      }
      return true;
    } else {
      if (!state.url.includes('/login') && !state.url.includes('/recupera-contrasenha')) {
        this.tokenData.stopTimer();
        this.router.navigate(["/login"]);
      }
      return false;
    }
  }
}